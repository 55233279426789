
import Vue from "vue";

import { mapActions } from "vuex"; // mapState

interface IActionsCards {
  level: string;
  name: string;
  subtitle: string;
  to: string;
  avatar: string;
}

export default Vue.extend({
  name: "SysAdmin",
  data() {
    return {
      // load local
      localLoading: true,
      // open form password
      dialog: false,
      // display password plain text
      showPass: false,
      // senha
      password: undefined as string | undefined,
      // informa se o usuário
      // pode fazer as alterações
      masterUser: false,
      // indica se uma action foi selecionada
      // se sim, mostra o view-router
      selected: false,
      // Actions Sys admin do pro market
      actionsCards: [
        {
          level: "critical",
          name: "harvest",
          subtitle: "Cadastro de safras",
          to: "sysmanager/harvest",
          avatar: "red",
        },
        {
          level: "critical",
          name: "growing",
          subtitle: "Cadastro de culturas",
          to: "sysmanager/growing",
          avatar: "green",
        },
        {
          level: "critical",
          name: "harvest-turn",
          subtitle: "Altera o sistema para a próxima safra",
          to: "sysmanager/harvest-turn",
          avatar: "blue",
        },
      ] as IActionsCards[],
    };
  },
  async mounted() {
    // utilizado quando faz reload
    // em uma action
    this.verifyPath();
    this.localLoading = true;
    await this.loadAllResources();
    this.localLoading = false;
  },
  methods: {
    ...mapActions({
      loadAllResources: "LOAD_ALL_RESOURCES",
    }),
    passMatch(event: Event): void {
      event.preventDefault();
      if (this.password === process.env.VUE_APP_MASTER_PASS) {
        this.masterUser = true;
      } else {
        this.masterUser = false;
      }
      this.dialog = false;
    },
    routeAction(action: string): void {
      this.selected = true;
      this.$router.push({ path: action });
    },
    verifyPath(): void {
      // verifica quando nenhuma action selecionada e path diferente do root
      // ai faz o push para o root para corrigir
      if (!this.selected && this.$route.name !== "Administração") {
        this.$router.push({ name: "Administração" });
      }
    },
  },
  watch: {
    dialog: function (val): void {
      if (val) {
        this.password = "";
      }
    },
    "$route.name": {
      handler: function (name): void {
        if (name === "Administração") this.selected = false;
      },
      deep: true,
      immediate: true,
    },
  },
});
